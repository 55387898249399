<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card flat class="mx-auto public-form transparent">
          <v-card outlined id="register">
            <v-card-text class="text-center">
              <v-img :src="$app.opts.authLogoSrc" :alt="$app.opts.title" max-height="250" contain />
            </v-card-text>
            <v-card-text class="text-center title">
              <h3>{{ $t('register') }}</h3>
            </v-card-text>
            <v-alert v-if="fromInvitation" :value="true" color="info" class="text-center my-0">
              {{ $t('invitation') }}
              <br />
            </v-alert>
            <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
            <v-card-text class="py-2">
              <v-form>
                <v-text-field
                  append-icon="$vuetify.icons.values.user"
                  v-model.trim="form.firstname"
                  :label="$t('labels.firstname')"
                  :error-messages="firstnameErrors"
                  required
                  @input="$v.form.firstname.$touch()"
                  @blur="$v.form.firstname.$touch()"
                  v-on:keyup.enter="doSubmit"
                />
                <v-text-field
                  append-icon="$vuetify.icons.values.user"
                  v-model.trim="form.lastname"
                  :label="$t('labels.lastname')"
                  :error-messages="lastnameErrors"
                  required
                  @input="$v.form.lastname.$touch()"
                  @blur="$v.form.lastname.$touch()"
                  v-on:keyup.enter="doSubmit"
                />
                <v-text-field
                  append-icon="$vuetify.icons.values.user"
                  v-model.trim="form.login"
                  :error-messages="loginErrors"
                  :label="$t('labels.login')"
                  required
                  @input="$v.form.login.$touch()"
                  @blur="$v.form.login.$touch()"
                />
                <v-text-field
                  append-icon="$vuetify.icons.values.password"
                  v-model.trim="form.password"
                  :error-messages="passwordErrors"
                  :label="$t('labels.password')"
                  type="password"
                  required
                  @input="$v.form.password.$touch()"
                  @blur="$v.form.password.$touch()"
                />
                <v-text-field
                  append-icon="$vuetify.icons.values.password"
                  v-model.trim="form.confirm_password"
                  :error-messages="confirm_passwordErrors"
                  :label="$t('labels.confirmPassword')"
                  type="password"
                  required
                  @input="$v.form.confirm_password.$touch()"
                  @blur="$v.form.confirm_password.$touch()"
                />
                <v-text-field
                  append-icon="$vuetify.icons.values.email"
                  v-model.trim="form.email"
                  :error-messages="emailErrors"
                  :label="$t('labels.email')"
                  required
                  @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                />
                <PhoneField v-model="form.phone" :errors="phoneErrors" />
                <LanguageField v-model="form.settings.language" />
                <FirmField v-model="form.firm_id" :errors="firmIdErrors" />
                <AgencyField v-model="form.settings.agency" :firm-id="form.firm_id" />
                <v-checkbox
                  v-model.trim="form.acceptTerms"
                  :error-messages="acceptTermsErrors"
                  :label="$t('acceptTerms')"
                  required
                  @change="$v.form.acceptTerms.$touch()"
                  @blur="$v.form.acceptTerms.$touch()"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn block color="accent" @click="doRegister" :loading="loading" :disabled="loading" depressed>
                {{ $t('labels.register') }}
              </v-btn>
            </v-card-actions>
            <v-card-text class="py-2 text-center">
              {{ $t('haveAccount') }}
              <br />
              <v-btn text color="default" :to="{ name: 'signin' }">{{ $t('labels.signin') }}</v-btn>
            </v-card-text>
          </v-card>
          <v-card flat class="my-3 transparent text-center">
            <LanguageField use-buttons :value="locale" immediate @input="setLocale" fieldClass="elevation-0" />
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <RefreshApp />
  </v-container>
</template>

<script>
import { omit } from 'lodash/object'
import { mapActions, mapGetters } from 'vuex'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import { BaseForm, FormErrors, LanguageField, PhoneField, RefreshApp } from '@argon/app/components'
import { isIntlPhoneNumber } from '@argon/app/helpers'
import { NAMESPACE } from '@argon/iam/auth/store'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { FirmField, AgencyField } from '@/firms/components'
const REFID = /\/me\/invitations\/(.*)/

export default {
  name: 'Register',
  extends: BaseForm,
  components: { FormErrors, LanguageField, PhoneField, FirmField, AgencyField, RefreshApp },
  validations: {
    form: {
      login: { required },
      password: { required },
      confirm_password: { required, sameAsPassword: sameAs('password') },
      email: { required, email },
      firstname: { required },
      lastname: { required },
      phone: { isIntlPhoneNumber },
      firm_id: { required },
      // https://github.com/vuelidate/vuelidate/issues/332
      acceptTerms: { beTrue: sameAs(() => true) }
    }
  },
  data: () => ({
    form: { settings: {} },
    loading: false
  }),
  computed: {
    ...mapGetters(ME_NAMESPACE, ['locale']),
    fromInvitation() {
      return REFID.test(this.$route.query.redirect)
    },
    invitationRefid() {
      if (this.fromInvitation) {
        return this.$route.query.redirect.match(REFID)[1]
      }
      return false
    },
    loginErrors() {
      const errors = []
      if (!this.$v.form.login.$dirty) return errors
      !this.$v.form.login.required && errors.push(this.$t('labels.required'))
      this.getServerErrors('login', errors)
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required && errors.push(this.$t('labels.required'))
      this.getServerErrors('password', errors)
      return errors
    },
    confirm_passwordErrors() {
      const errors = []
      if (!this.$v.form.confirm_password.$dirty) return errors
      !this.$v.form.confirm_password.required && errors.push(this.$t('labels.required'))
      !this.$v.form.confirm_password.sameAsPassword && errors.push(this.$t('mismatch'))
      this.getServerErrors('confirm_password', errors)
      return errors
    },
    firstnameErrors() {
      const errors = []
      if (!this.$v.form.firstname.$dirty) return errors
      !this.$v.form.firstname.required && errors.push(this.$t('labels.required'))
      return errors
    },
    lastnameErrors() {
      const errors = []
      this.getServerErrors('lastname', errors)
      if (!this.$v.form.lastname.$dirty) return errors
      !this.$v.form.lastname.required && errors.push(this.$t('labels.required'))
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.required && errors.push(this.$t('labels.required'))
      !this.$v.form.email.email && errors.push(this.$t('labels.invalidEmail'))
      this.getServerErrors('email', errors)
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.form.phone.$dirty) return errors
      !this.$v.form.phone.isIntlPhoneNumber && errors.push(this.$t('phoneWrongFormat'))
      this.getServerErrors('phone', errors)
      return errors
    },
    firmIdErrors() {
      const errors = []
      if (!this.$v.form.firm_id.$dirty) return errors
      !this.$v.form.firm_id.required && errors.push(this.$t('labels.required'))
      this.getServerErrors('firm_id', errors)
      return errors
    },
    acceptTermsErrors() {
      const errors = []
      if (!this.$v.form.acceptTerms.$dirty) return errors
      !this.$v.form.acceptTerms.beTrue && errors.push(this.$t('labels.required'))
      this.getServerErrors('acceptTerms', errors)
      return errors
    },
    nextPath() {
      return this.$route.query.redirect ? { path: this.$route.query.redirect } : { name: 'overview' }
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['register', 'getInvitation']),
    ...mapActions(ME_NAMESPACE, ['setLocale']),
    setPhone(v) {
      this.form.phone = v
      this.$v.form.phone.$touch()
    },
    setLanguage(v) {
      this.form.settings.language = v
    },
    setFirmId(v) {
      this.form.firm_id = v
      this.$v.form.firm_id.$touch()
    },
    doRegister() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.loading = true
      let payload = omit(this.form, ['acceptTerms'])
      this.register(payload)
        .then(() => {
          this.$router.push(this.nextPath)
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    if (this.invitationRefid) {
      this.getInvitation(this.invitationRefid)
        .then((data) => {
          this.form.email = data.email
        })
        .catch(() => {})
    }
  }
}
</script>

<i18n>
{
  "en": {
    "acceptTerms": "I read and accept terms and conditions",
    "haveAccount": "Already have an account?",
    "invitation": "You will be redirected to your invitation."
  },
  "fr": {
    "acceptTerms": "J'ai lu et accepté les termes et conditions",
    "haveAccount": "Vous avez déjà un compte?",
    "invitation": "Vous serez redirigée vers votre invitation."
  }
}
</i18n>
